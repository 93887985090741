import styled, { keyframes } from 'styled-components/macro';
import CSSTransition from 'react-transition-group/CSSTransition';

// Animations
const LeadAnimation = keyframes`
  0%{
    opacity: 0;
    transform: scale(1, 0.5) translateX(-100%);
  }
  33% {
    opacity: 1;
  }
  50%{
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0, 0.9) translateX(0);
  }
`;

// Styles
const Wrapper = styled.div`
  z-index: 401;
  position: relative;
  width: 100%;
  height: 2px;
  /* margin-top: -1px; */
  margin-bottom: -2px;
  overflow: hidden;

  &.enter,
  &.appear {
    opacity: 0;
  }
  &.appear-active,
  &.enter-active,
  &.enter-done {
    transition: opacity 500ms 50ms;
    opacity: 1;
  }
  &.exit-active {
    transition: opacity 300ms;
    opacity: 0;
  }
`;
const Tracer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: ${({ theme }) => theme.itemInvertedBg};

  transform-origin: top right;
  animation: ${LeadAnimation} 800ms ${({ theme }) => theme.ease.standard} infinite both 50ms;
`;

// Component
const Loader = ({ show = true }) => (
  <CSSTransition in={show} timeout={550} mountOnEnter unmountOnExit appear>
    <Wrapper>
      <Tracer />
    </Wrapper>
  </CSSTransition>
);
export default Loader;
