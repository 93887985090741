import styled from 'styled-components/macro';

import { db } from '../lib/firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useRecoilState } from 'recoil';
import isDarkThemeAtom from '../state/isDarkThemeAtom';

import { NavLink } from 'react-router-dom';

import { ReactComponent as LightThemeSvg } from '../svg/light_theme.svg';
import { ReactComponent as DarkThemeSvg } from '../svg/dark_theme.svg';

// Styles
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 24px;
`;
const TagCloud = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Tag = styled(NavLink)`
  margin: 0 8px 8px 0;
  padding: 4px 16px;
  @media (max-width: 768px) {
  }

  color: ${({ theme }) => theme.textFaded};
  border-radius: 999px;
  border: 1px solid ${({ theme }) => theme.itemBorder};
  background-color: ${({ theme }) => theme.itemBg};
  &:hover {
    color: ${({ theme }) => theme.textColor};
  }
  &.active {
    color: ${({ theme }) => theme.bgColor};
    border: 1px solid ${({ theme }) => theme.itemInvertedBorder};
    background-color: ${({ theme }) => theme.itemInvertedBg};
  }

  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
`;
const ThemeButtons = styled.div`
  flex-shrink: 0;
`;
const ThemeButton = styled.button`
  margin-left: 12px;
  opacity: 0.5;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
    path {
      fill: ${({ theme }) => theme.textColor};
    }
  }
`;

// Component
export default function TagRow() {
  const [isDarkTheme, setIsDarkTheme] = useRecoilState(isDarkThemeAtom);
  const [tagsDoc] = useDocumentData<{
    tags: string[];
  }>(db.collection('mosteveryday').doc('tags'));

  return (
    <Wrapper>
      <TagCloud>
        <Tag to='/all'>all</Tag>
        {tagsDoc?.tags.map(tag => (
          <Tag key={tag} to={`/${tag}`}>
            {tag}
          </Tag>
        ))}
      </TagCloud>
      {/* <Expanded /> */}
      <ThemeButtons>
        <ThemeButton className={isDarkTheme ? '' : 'active'} onClick={() => setIsDarkTheme(false)}>
          <LightThemeSvg />
        </ThemeButton>
        <ThemeButton className={isDarkTheme ? 'active' : ''} onClick={() => setIsDarkTheme(true)}>
          <DarkThemeSvg />
        </ThemeButton>
      </ThemeButtons>
    </Wrapper>
  );
}
