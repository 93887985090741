import { DefaultTheme } from 'styled-components/macro';
import lightTheme, { colors } from './lightTheme';

const darkTheme: DefaultTheme = {
  // Duplicate defaults from light
  ...lightTheme,

  // Override light semantics
  bgColor: colors.gray[700],
  // sheetColor: colors.gray[900],
  textColor: colors.gray[300],
  textFaded: colors.gray[400],
  itemBg: colors.gray[600],
  itemBorder: colors.gray[500],
  itemInvertedBg: colors.gray[300],
  itemInvertedBorder: colors.gray[200],
  // hoverBg: colors.gray[800],
  // dividerColor: colors.gray[700],

  // sheetShadow: '2px 4px 12px rgba(23, 23, 23, 0.5), 2px 4px 8px rgba(23, 23, 23, 0.35)',
};

export default darkTheme;
