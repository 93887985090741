import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyD4ylZ8qhVuNUlnxSSVBf_n3vXjepr67eY',
  authDomain: 'mosteveryday-firebase.firebaseapp.com',
  projectId: 'mosteveryday-firebase',
  storageBucket: 'mosteveryday-firebase.appspot.com',
  messagingSenderId: '846111121517',
  appId: '1:846111121517:web:eb2c4eb18e7f31b216df64',
  measurementId: 'G-QT39MN0LGQ',
};

// Initialize firebase (if isn't already)
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// Initialize Google Auth through Firebase
export const auth = firebase.auth();

// Initialize Cloud Firestore through Firebase
export const db = firebase.firestore();
export const fieldValue = firebase.firestore.FieldValue;

// Initialize Cloud Storage through Firebase
export const storage = firebase.storage();

export default firebase;
