import styled from 'styled-components/macro';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainAside from './components/MainAside';
import TagRow from './components/TagRow';
import Items from './components/Items';
import FileUploader from './components/FileUploader';

// Styles
const SiteWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  gap: 32px;
  padding: 48px 16px;
  @media (max-width: 768px) {
    display: block;
    padding: 16px;
  }
`;
const MainContent = styled.div`
  flex: 1;
  /* border: 1px dashed rgba(0, 0, 0, 0.1); */
`;
const Aside = styled.div`
  flex-shrink: 0;
  width: 128px;
  margin: 0 auto;
  padding-bottom: 24px;
  /* border: 1px dashed rgba(0, 0, 0, 0.1); */
`;

// Component
export default function App() {
  return (
    <Switch>
      <Route path='/:tag'>
        <FileUploader />

        <SiteWrapper>
          {/* Main Aside */}
          <Aside>
            <MainAside />
          </Aside>

          {/* Main Content */}
          <MainContent>
            <TagRow />
            <Items />
          </MainContent>

          {/* Blank Aside ( for layout ) */}
          <Aside />
        </SiteWrapper>
      </Route>
      <Route>
        <Redirect to='/all' />
      </Route>
    </Switch>
  );
}
