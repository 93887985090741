import { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import { useAudio } from 'react-use';

import { ReactComponent as SpeakerSvg } from '../svg/speaker.svg';
import { ReactComponent as PauseSvg } from '../svg/pause.svg';
import { ReactComponent as PlaySvg } from '../svg/play.svg';
import { relativeDate } from '../lib/helpers';
import { useRecoilState } from 'recoil';
import activeItemAtom from '../state/activeItem';

import { useDownloadURL } from 'react-firebase-hooks/storage';
import { storage } from '../lib/firebase';

// Styles

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemButton = styled.button<{ isActive?: boolean }>`
  position: relative;
  width: 128px;
  height: 128px;
  margin-bottom: 8px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.itemBorder};
  background-color: ${({ theme }) => theme.itemBg};

  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  svg path {
    fill: ${({ theme }) => theme.textFaded};
    transition: fill 200ms ease;
  }
  &:hover {
    opacity: 1;
    svg path {
      fill: ${({ theme }) => theme.textColor};
    }
  }

  .stroke-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    rect {
      fill: transparent;
      stroke: ${({ theme }) => theme.textColor};
      stroke-width: 2px;
      stroke-dasharray: 500;
      /* stroke-dashoffset: 500; */
      transition: stroke-dashoffset 100ms linear;
    }
  }
  /* &:hover .stroke-svg rect {
    transition: stroke-dashoffset 5000ms linear;
    stroke-dashoffset: 0;
  } */
`;
const TimeRemaining = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.05em;
`;
const ItemDate = styled.div`
  padding-bottom: 2px;
  font-weight: 600;
`;
const ItemTitle = styled.div`
  font-size: 12px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.textFaded};
`;

// Component
interface ItemProps {
  iso: string;
  name: string;
}
export default function Item({ iso, name }: ItemProps) {
  const [src, , error] = useDownloadURL(storage.ref(`${iso}.mp3`));

  const [activeItem, setActiveItem] = useRecoilState(activeItemAtom);
  const isActive = activeItem === iso;

  const [audio, state, controls] = useAudio({
    src: src ?? '',
    // src: url,
    preload: 'auto',
  });
  // Pause and reset anytime becomes not active
  useEffect(() => {
    if (!isActive) {
      controls.pause();
      controls.seek(0);
    }
  }, [controls, isActive]);

  // Time remaining
  const timeRemaining = useMemo(() => {
    const totalSeconds = state.duration - state.time;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  }, [state.duration, state.time]);

  if (error) return null;

  return (
    <Wrapper>
      {audio}
      <ItemButton
        isActive={isActive}
        onClick={() => {
          setActiveItem(iso);
          if (state.paused) {
            controls.play();
          } else {
            controls.pause();
          }
        }}
      >
        {(isActive || !state.paused) && <TimeRemaining>{timeRemaining}</TimeRemaining>}
        {/* Speaker icon ( when not active ) */}
        {state.paused && !isActive && <SpeakerSvg />}
        {/* Play icon ( when paused and active ) */}
        {state.paused && !!isActive && <PlaySvg />}
        {/* Paused icon ( when playing ) */}
        {!state.paused && <PauseSvg />}

        {/* Playback outline */}
        <svg
          version='1.1'
          viewBox='0 0 128 128'
          xmlns='http://www.w3.org/2000/svg'
          className='stroke-svg'
        >
          <rect
            x='1'
            y='1'
            width='126'
            height='126'
            rx='3'
            strokeDashoffset={500 - (state.time / (state.duration || 100)) * 500}
          />
        </svg>
      </ItemButton>
      <ItemDate>{relativeDate(iso)}</ItemDate>
      <ItemTitle>{name}</ItemTitle>
    </Wrapper>
  );
}
