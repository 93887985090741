import { DateTime } from 'luxon';
export const relativeDateTime = (date: Date) => {
  const NOW = DateTime.local();
  const dateTime = DateTime.fromJSDate(date);
  // Today
  if (dateTime.hasSame(NOW, 'day')) {
    const time = dateTime.toLocaleString({ hour: 'numeric', minute: '2-digit' });
    return `today - ${time}`;
  }
  // Yesterday
  if (dateTime.hasSame(NOW.minus({ days: 1 }), 'day')) {
    const time = dateTime.toLocaleString({ hour: 'numeric', minute: '2-digit' });
    return `yesterday - ${time}`;
  }
  const dateDiff = NOW.diff(dateTime);
  const daysAgo = Math.round(dateDiff.as('days'));
  let relative = `${daysAgo}d`;
  if (daysAgo > 30) {
    const weeksAgo = Math.round(dateDiff.as('weeks'));
    relative = `${weeksAgo}w`;
    if (weeksAgo > 9) {
      const monthsAgo = Math.round(dateDiff.as('months'));
      relative = `${monthsAgo}mo`;
    }
  }

  // This year
  if (dateTime.hasSame(NOW, 'year')) {
    const formattedDate = dateTime
      .toLocaleString({ month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' })
      .toLowerCase();
    return `${relative} - ${formattedDate}`;
  }
  // Before this year
  const formattedDate = dateTime
    .toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })
    .toLowerCase();
  return `${relative} - ${formattedDate}`;
};
export const relativeDate = (iso: string) => {
  const NOW = DateTime.local();
  const dateTime = DateTime.fromISO(iso);
  // Today
  if (dateTime.hasSame(NOW, 'day')) {
    return `today`;
  }
  // Yesterday
  if (dateTime.hasSame(NOW.minus({ days: 1 }), 'day')) {
    return `yesterday`;
  }

  // This year
  if (dateTime.hasSame(NOW, 'year')) {
    return dateTime.toLocaleString({ month: 'short', day: 'numeric' }).toLowerCase();
  }
  // Before this year
  return dateTime
    .toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    .toLowerCase();
};
