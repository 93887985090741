import { createGlobalStyle, keyframes } from 'styled-components/macro';

export const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;
export const fadeOut = keyframes`
    0% { opacity: 1; }
  100% { opacity: 0; }
`;

const BaseStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }
  html, body, #root {
    position: relative;
    min-height: 100%;
    width: 100%;
    width: 100vw;
    background-color: ${({ theme }) => theme.bgColor};
    color: ${({ theme }) => theme.textColor};
    text-transform: uppercase;

    transition: background-color 300ms ease, color 300ms ease;
  }
  a, button{
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    text-decoration: none;
    color: ${({ theme }) => theme.gray[500]};
    &:not(:disabled){
      cursor: pointer;
    }
    transition: opacity 300ms ease, background-color 300ms ease, color 300ms ease, border 300ms ease;
  }

  ::selection {
    background: rgba(59, 130, 246, .9);
    color: ${({ theme }) => theme.gray[100]};
  }

  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ease 150ms forwards;
  }
  .fade-out {
    animation: ${fadeOut} 400ms ease 3500ms forwards;
  }
`;

export default BaseStyles;
