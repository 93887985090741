import { useMemo } from 'react';
import styled from 'styled-components/macro';

import { useParams } from 'react-router-dom';
import { db } from '../lib/firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import orderBy from 'lodash/orderBy';

import Item from './Item';

// Styles
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-around;
  &::after {
    content: '';
    flex: auto;
  }
`;

// Component
export default function Items() {
  const { tag } = useParams<{ tag: string }>();
  const [itemsDoc] = useDocumentData<{
    [iso: string]: { name: string; tags: string[] };
  }>(db.collection('mosteveryday').doc('items'));
  const filteredItems = useMemo(() => {
    if (!itemsDoc) return [];
    const items = Object.keys(itemsDoc).map(iso => ({ iso, ...itemsDoc[iso] }));
    const orderedItems = orderBy(items, 'iso', 'desc');
    if (tag === 'all') return orderedItems;
    return orderedItems.filter(({ tags }) => tags.includes(tag));
  }, [itemsDoc, tag]);

  return (
    <Wrapper>
      {filteredItems.map(({ iso, name }) => (
        <Item key={iso} iso={iso} name={name} />
      ))}
    </Wrapper>
  );
}
