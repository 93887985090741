import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { auth, db, storage } from '../lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDropzone } from 'react-dropzone';
import { DateTime } from 'luxon';
import Loader from './Loader';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useRef } from 'react';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.itemBg};
  border-radius: 4px;
`;
const DragWrapper = styled.div<{ dragging: boolean }>`
  border: 2px dashed
    ${({ dragging, theme }) => (dragging ? theme.itemInvertedBorder : theme.itemBorder)};
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  &:hover {
    border: 2px dashed ${({ theme }) => theme.itemInvertedBorder};
  }
`;
const FlexRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;
const Expanded = styled.div`
  flex: 1;
  width: 100%;
  max-width: 256px;
`;
const Input = styled.input`
  flex: 1;
  width: 100%;
  max-width: 256px;
  display: block;
  background-color: transparent;
  margin-top: 12px;
  border: 2px solid ${({ theme }) => theme.itemBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.textColor};
  outline: none;
  &:focus {
    border: 2px solid ${({ theme }) => theme.itemInvertedBorder};
  }
`;

export default function FileUploader() {
  const [user] = useAuthState(auth);

  const [isoInput, setIsoInput] = useState(DateTime.now().toISODate());
  const todayIso = useMemo(() => {
    const inputIso = DateTime.fromISO(isoInput).toISODate();
    return inputIso ?? DateTime.now().toISODate();
  }, [isoInput]);

  // Handle new upload
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      acceptedFiles.length && console.log('acceptedFiles', acceptedFiles);
      if (acceptedFiles.length) {
        setIsUploading(true);
        const file = acceptedFiles[0];
        // const dateIso = DateTime.now().toISODate();
        const storageRef = storage.ref().child(`${todayIso}.mp3`);
        await storageRef.put(file);
        await db
          .doc('mosteveryday/items')
          .update(todayIso, { name: file.name.replace('.mp3', ''), tags: [] });
        setIsUploading(false);
      }
    },
    [todayIso]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'audio/mpeg',
  });

  // Get today's info
  const inputHasFocus = useRef(false);
  const [nameInput, setNameInput] = useState('');
  const [tagInputs, setTagInputs] = useState<string[]>([]);
  const [itemsDoc] = useDocumentData<{
    [iso: string]: { name: string; tags: string[] };
  }>(db.collection('mosteveryday').doc('items'));
  const todayDoc = useMemo(() => {
    if (!itemsDoc) return null;
    const todayDoc = itemsDoc[todayIso];
    if (todayDoc) {
      if (!inputHasFocus.current) {
        setNameInput(todayDoc.name);
        setTagInputs([...todayDoc.tags, '']);
      }
      return todayDoc;
    }
  }, [itemsDoc, todayIso]);

  if (!user) return null;
  return (
    <Wrapper>
      <Loader show={isUploading} />
      <DragWrapper {...getRootProps()} dragging={isDragActive}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop it like it's hot 🔥🔥🔥</p>
        ) : (
          <p>Drag 'n' drop an .mp3 here, or click to select file</p>
        )}
      </DragWrapper>
      {/* Edit today's info */}
      <FlexRow>
        <Input value={isoInput} onChange={e => setIsoInput(e.currentTarget.value)} />
        {todayDoc && (
          <>
            <Input
              value={nameInput}
              onChange={e => setNameInput(e.currentTarget.value)}
              onFocus={() => (inputHasFocus.current = true)}
              onBlur={() => {
                inputHasFocus.current = false;
                db.doc('mosteveryday/items').update(`${todayIso}.name`, nameInput.trim());
              }}
            />
            <Expanded>
              {tagInputs.map((tagInput, idx) => (
                <Input
                  key={idx}
                  value={tagInput}
                  onChange={e => {
                    const val = e.currentTarget.value;
                    setTagInputs(prev => {
                      const next = [...prev];
                      next[idx] = val;
                      return next;
                    });
                  }}
                  onFocus={() => (inputHasFocus.current = true)}
                  onBlur={() => {
                    inputHasFocus.current = false;
                    const filteredTags = tagInputs.filter(tagInput => !!tagInput.trim());
                    db.doc('mosteveryday/items').update(`${todayIso}.tags`, filteredTags);
                  }}
                />
              ))}
            </Expanded>
          </>
        )}
      </FlexRow>
    </Wrapper>
  );
}
