import { DefaultTheme } from 'styled-components/macro';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  // primary: {
  //   50: '#eff6ff',
  //   100: '#dbeafe',
  //   200: '#bfdbfe',
  //   300: '#93c5fd',
  //   400: '#60a5fa',
  //   500: '#3b82f6',
  //   600: '#2563eb',
  //   700: '#1d4ed8',
  //   800: '#1e40af',
  //   900: '#1e3a8a',
  // },
  gray: {
    50: '#FAFAF9',
    100: '#F5F5F4',
    200: '#E7E5E4',
    300: '#D6D3D1',
    350: '#B3AEAA',
    400: '#A8A29E',
    500: '#78716C',
    600: '#57534E',
    700: '#44403C',
    800: '#292524',
    900: '#1C1917',
  },
};

const lightTheme: DefaultTheme = {
  ...colors,
  // Defaults
  ease: {
    standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  },

  // Semantics
  bgColor: colors.gray[100],
  // sheetColor: colors.gray[100],
  textColor: colors.gray[500],
  textFaded: colors.gray[400],
  itemBg: colors.gray[200],
  itemBorder: colors.gray[300],
  itemInvertedBg: colors.gray[500],
  itemInvertedBorder: colors.gray[600],
  // hoverBg: colors.gray[200],
  // dividerColor: colors.gray[300],

  // sheetShadow: '2px 4px 8px rgba(23, 23, 23, 0.08), 2px 4px 12px rgba(23, 23, 23, 0.12)',
};

export default lightTheme;
