import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components/macro';
import firebase, { auth } from '../lib/firebase';
import isDarkThemeAtom from '../state/isDarkThemeAtom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { ReactComponent as LogoSvg } from '../svg/logo.svg';

// Styles
const Logo = styled(LogoSvg)<{ isDarkTheme?: boolean }>`
  width: 100%;
  circle {
    transition: fill 300ms ease;
  }
  rect {
    transition: fill 300ms ease;
  }
  ${({ isDarkTheme }) =>
    isDarkTheme &&
    css`
      circle {
        fill: ${({ theme }) => theme.gray[600]};
      }
      rect {
        fill: ${({ theme }) => theme.gray[500]};
      }
    `};
`;

// Component
export default function MainAside() {
  const [user] = useAuthState(auth);
  // Only I'm allowed
  useEffect(() => {
    if (user && user.email !== 'chris@chrisdoedesign.com') {
      auth.signOut();
    }
  }, [user]);

  const isDarkTheme = useRecoilValue(isDarkThemeAtom);
  const logoClicks = useRef(0);
  const handleLogoClick = useCallback(() => {
    logoClicks.current++;
    console.log('logoClicks.current', logoClicks.current);
    // After 5 clicks
    if (logoClicks.current >= 5) {
      // If logged in, log out
      if (user) auth.signOut();
      // If not logged in, log in
      else {
        var provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithRedirect(provider);
      }
      // Reset clicks
      logoClicks.current = 0;
    }
  }, [user]);
  return (
    <>
      <Logo isDarkTheme={isDarkTheme} onClick={handleLogoClick} />
    </>
  );
}
